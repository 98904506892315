<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      small="size"
      label-width="120px"
    >
      <!-- <el-row>
        <el-col :span="8"> -->
      <el-form-item label="医生团队名称：">
        <el-select v-model="formInline.teamCode" placeholder="请选择医生团队">
          <el-option
            v-for="item in doctorTeamList"
            :key="item.teamCode"
            :value="item.teamCode"
            :label="item.teamName"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
        <el-col :span="8"> -->
      <el-form-item label="姓名：">
        <el-input
          v-model="formInline.doctorName"
          v-check-permission
          placeholder="请输入医生姓名"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="8"> -->
      <el-form-item label="手机号：">
        <el-input
          v-model="formInline.mobile"
          v-check-permission
          placeholder="请输入手机号"
        />
      </el-form-item>
      <!-- </el-col>
      </el-row>
      <el-row> -->
      <!-- <el-col :span="9"> -->
      <el-form-item label="状态：">
        <el-select v-model="formInline.activateStatus">
          <el-option
            v-for="item in statusTypeList"
            :key="item.text"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
        <el-col :span="8"> -->
      <span style="margin-right: 60px">&nbsp;</span>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
        <el-button
          v-if="$checkPermission('IMPORTWHITELIST', $route)"
          type="primary"
          @click="importData()"
        >
          导入
        </el-button>
        <el-button
          v-if="$checkPermission('DOWNLADTEMPLATE', $route)"
          type="primary"
          @click="downloadTemplate()"
        >
          下载模板
        </el-button>
      </el-form-item>
      <!-- </el-col>
      </el-row> -->
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="teamName" label="医生团队" align="center" />
      <el-table-column prop="doctorName" label="姓名" align="center" />
      <el-table-column prop="mobile" label="手机号" align="center" />
      <el-table-column prop="activateTime" label="状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.activateTime ? '激活' : '未激活' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateBy" label="操作人" align="center" />
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        width="150"
      />
      <el-table-column
        prop="activateTime"
        label="激活时间"
        align="center"
        width="150"
      />
      <!-- <el-table-column
        label="星期一"
        width="200"
        align="center"
      >
        <el-table-column
          label="上午"
          align="center"
        >
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.a" />
          </template>
        </el-table-column>
        <el-table-column
          label="下午"
          align="center"
        >
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.b" />
          </template>
        </el-table-column>
      </el-table-column> -->
      <el-table-column label="操作" width="200" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.activateTime"
            type="primary"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            提醒
          </el-button>
          <el-button
            v-if="!scope.row.activateTime"
            type="primary"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <div>
        <el-switch
          v-model="status"
          style="display: block"
          @change="
            (val) => {
              onchange(val);
            }
          "
        />
        <div class="notice">注:关闭后医生注册不需进行白名单校验</div>
      </div>
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <importList
      v-if="importDialogVisible"
      :visible="importDialogVisible"
      :title-type="whiteList"
      @cancel="importDialogVisible = false"
      @refreshList="refreshList"
    />
  </div>
</template>

<script>
import importList from '../doctorList/component/importData'; // 导入清单弹窗
import { mapActions } from 'vuex';
export default {
  name: 'ServiceManageList',
  components: {
    importList,
  },
  data() {
    this.statusTypeList = [
      { text: '全部', value: '' },
      { text: '激活', value: true },
      { text: '未激活', value: false },
    ];
    return {
      whiteList: 'whiteList',
      importDialogVisible: false,
      loading: false,
      formInline: {
        doctorName: '',
        teamCode: '',
        mobile: '',
        activateStatus: '',
      },
      doctorTeamList: [],
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      status: false, // 是否关闭白名单校验
      orderListData: [], // 服务列表
    };
  },
  computed: {
    // ...mapState('doctorTeam', {
    //   doctorTeamList: state => state.allDoctorList
    // }),
  },
  mounted() {
    this.getWhiteList();
    this.getTeamList();
    this.getWhiteConfigStatus();
  },
  methods: {
    ...mapActions('doctorManage', ['whitePageQuery', 'remind']),
    ...mapActions('doctorTeam', ['queryList']),
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getWhiteList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getWhiteList();
    },
    // 刷新列表
    refreshList(value) {
      if (value === 'sucess') {
        this.pageInfo.page = 1;
        this.getWhiteList();
      }
      this.importDialogVisible = false;
    },
    // 导入白名单数据
    importData() {
      this.importDialogVisible = true;
    },
    // 查询团队列表
    getTeamList() {
      this.queryList({}).then((res) => {
        console.log(res.data.data);
        const list = res.data;
        list.unshift({
          teamCode: '',
          teamName: '全部',
        });
        this.doctorTeamList = list;
      });
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        doctorName: '',
        teamCode: '',
        mobile: '',
        activateStatus: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getWhiteList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getWhiteList();
    },
    // 分页查询白名单列表
    getWhiteList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.whitePageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      if (type === 0) {
        this.receieveRemind(item);
      } else {
        this.delWhite(item);
      }
    },
    // 提醒
    receieveRemind(item) {
      const param = {
        id: item.id,
      };
      this.remind(param).then((res) => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '提醒成功',
          });
        }
      });
    },
    // 删除白名单
    delWhite(item) {
      this.$confirm('确认' + item.doctorName + '白名单信息删除吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const param = {
            id: item.id,
          };
          this.$api.whiteDelete(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '删除白名单成功!',
              });
              this.getWhiteList();
            }
          });
        })
        .catch(() => {});
    },
    // 模板下载
    downloadTemplate() {
      var url =
        'https://manniudoctor.oss-cn-shanghai.aliyuncs.com/model/doctor_white.xlsx';
      window.open(url);
    },
    // 开启/关闭白名单校验弹框
    onchange(val) {
      const name = val ? '打开' : '关闭';
      this.$confirm('是否确认' + name + '白名单校验?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
      })
        .then(() => {
          this.changeWhiteStatus(val);
        })
        .catch(() => {
          this.status = !val;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // 查询白名单配置状态
    getWhiteConfigStatus() {
      this.$api.queryWhiteConfigStatus({}).then((res) => {
        this.status = res.data;
      });
    },
    // 改变白名单配置状态
    changeWhiteStatus(val) {
      const param = {
        switchStatus: val,
      };
      this.$api.operateWhiteStatus(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('状态更新成功');
          this.getWhiteConfigStatus();
        }
      });
    },
  },
};
</script>
<style>
.el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
.notice {
  color: red;
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0;
}
</style>
